import React from "react";
import { motion } from "framer-motion"
import './loader.css'


function Loader(props) {
    return (
        props.showLoader ?
            <motion.div
                initial={{ y: 0 }}
                animate={{ y: "-100%" }}
                transition={{ delay: 2.5, duration: .7, ease: [1, -0.01, .44, .97] }}
                onAnimationComplete={() => props.setShowLoader(false)}
                className="loader" >
                <div className="loader-text__wrapper">
                    <motion.div
                        initial={{ y: 250 }}
                        animate={{ y: 0 }}
                        transition={{ delay: .7, duration: 1.2, type: "spring" }}
                        className="loader-text">Nimbus</motion.div>
                </div >
            </motion.div > : false
    )
}

export default Loader