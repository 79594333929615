import React from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import './project-card.css'
import Footer from "../../components/footer/footer";
import NextProject from "../../components/next-project/next-project";
import ImageSize from "../../components/image-size/image-size";
import project from '../../data/data.json'

function ProjectCard() {
    const params = useParams()

    let nextProject
    const findedProject = project.find(function (item, id) {
        if (item.id == params.id) {
            if (id == 0) {
                nextProject = project[project.length - 1]
            } else {
                nextProject = project[id - 1]
            }
            return item
        }
        return false
    })

    return (
        <motion.div className="main-wrapper"
            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            transition={{ duration: 0.7, ease: "easeOut" }}
            exit={{ opacity: 0 }}
        >
            < section className="project-card-section" >
                <div className="project-card__main-img-wrapper">
                    <img className="project-card__main-img" src={`/static/media/projects/${findedProject.img_1}`} alt="main-img" />
                </div>
                <h2 className="project-card__title">{findedProject.title}</h2>

                <div className="project-card__content-wrapper">
                    <div className="project-card__description-wrapper">
                        <span className="project-card__description-title">Описание</span>
                        <p className="project-card__description">{findedProject.description}</p>
                    </div>
                    <div className="project-card__client-wrapper">
                        <div className="project-card__client-block">
                            <span className="project-card__client-title">Клиент</span>
                            <div className="project-card__client-link__wrapper">
                                <a target={"_blank"} href={findedProject.link} className="project-card__client">{findedProject.client}</a>
                            </div>
                        </div>
                        <div className="project-card__industry-block">
                            <span className="project-card__industry-title">Индустрия</span>
                            <p className="project-card__industry">{findedProject.industry}</p>
                        </div>
                        <div className="project-card__services-block">
                            <span className="project-card__services-title">Услуги</span>
                            <ul className="project-card__services-list">
                                <li className="project-card__services-item">{findedProject.services_1}</li>
                                <li className="project-card__services-item">{findedProject.services_2}</li>
                                <li className="project-card__services-item">{findedProject.services_3}</li>
                                <li className="project-card__services-item">{findedProject.services_4}</li>
                            </ul>
                        </div>
                        <div className="project-card__year-block">
                            <span className="project-card__year-title">Год</span>
                            <p className="project-card__year">{findedProject.year}</p>
                        </div>
                    </div>
                </div>
                <div className="project-card__main-video-wrapper">
                    {findedProject.orientation == 0 ?
                        <>
                            {(findedProject.video_1 || '').trim().length === 0 ? false :
                                <iframe className="project-card__main-video--vertical" title="vimeo-player"
                                    src={`https://player.vimeo.com/video/${findedProject.video_1.substr(18)}?loop=1&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                                </iframe >
                            }
                            {(findedProject.video_2 || '').trim().length === 0 ? false :
                                <iframe className="project-card__main-video--vertical" title="vimeo-player"
                                    src={`https://player.vimeo.com/video/${findedProject.video_2.substr(18)}?loop=1&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                                </iframe >
                            }
                            {(findedProject.video_3 || '').trim().length === 0 ? false :
                                <iframe className="project-card__main-video--vertical" title="vimeo-player"
                                    src={`https://player.vimeo.com/video/${findedProject.video_3.substr(18)}?loop=1&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                                </iframe >
                            }
                            {(findedProject.video_4 || '').trim().length === 0 ? false :
                                <iframe className="project-card__main-video--vertical" title="vimeo-player"
                                    src={`https://player.vimeo.com/video/${findedProject.video_4.substr(18)}?loop=1&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                                </iframe >
                            }
                        </>
                        :
                        <>
                            {(findedProject.video || '').trim().length === 0 ? false :
                                <iframe className="project-card__main-video--horizontal" title="vimeo-player"
                                    src={`https://player.vimeo.com/video/${findedProject.video.substr(18)}?loop=1&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                                </iframe >
                            }
                            <div className="vertical-video__wrapper">
                                {(findedProject.video_1 || '').trim().length === 0 ? false :
                                    <iframe className="project-card__main-video--vertical" title="vimeo-player"
                                        src={`https://player.vimeo.com/video/${findedProject.video_1.substr(18)}?loop=1&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                                    </iframe >
                                }
                                {(findedProject.video_2 || '').trim().length === 0 ? false :
                                    <iframe className="project-card__main-video--vertical" title="vimeo-player"
                                        src={`https://player.vimeo.com/video/${findedProject.video_2.substr(18)}?loop=1&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                                    </iframe >
                                }
                                {(findedProject.video_3 || '').trim().length === 0 ? false :
                                    <iframe className="project-card__main-video--vertical" title="vimeo-player"
                                        src={`https://player.vimeo.com/video/${findedProject.video_3.substr(18)}?loop=1&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                                    </iframe >
                                }
                                {(findedProject.video_4 || '').trim().length === 0 ? false :
                                    <iframe className="project-card__main-video--vertical" title="vimeo-player"
                                        src={`https://player.vimeo.com/video/${findedProject.video_4.substr(18)}?loop=1&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                                    </iframe >
                                }
                            </div>
                        </>
                    }
                </div >

                <div className="project-card__img-wrapper">
                    {
                        <ImageSize img={findedProject.img_2} />
                    }
                    {
                        <ImageSize img={findedProject.img_3} />
                    }
                    {
                        <ImageSize img={findedProject.img_4} />
                    }
                    {
                        <ImageSize img={findedProject.img_5} />
                    }
                    {
                        <ImageSize img={findedProject.img_6} />
                    }
                    {
                        <ImageSize img={findedProject.img_7} />
                    }
                    {
                        <ImageSize img={findedProject.img_8} />
                    }
                    {
                        <ImageSize img={findedProject.img_9} />
                    }
                    {
                        <ImageSize img={findedProject.img_10} />
                    }
                    {
                        <ImageSize img={findedProject.img_11} />
                    }
                </div>

            </section >
            <NextProject nextProject={nextProject} />
            <Footer />
        </motion.div>
    )
}

export default ProjectCard