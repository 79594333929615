import React from "react";
import './projects-section.css'
import { Link } from "react-router-dom";
import VideoProject from '../../components/video-project/video-project'
import project from '../../data/data.json'



function ProjectsSection(props) {

    let filteredHorizontalVideo = []

    project.forEach((item, id) => {
        if (item.orientation == 1) {
            if (item.home_page == 1) {
                filteredHorizontalVideo.push(item)
            }
        }
    });

    return (
        <section className={props.theme ? "projects-section projects-section--active" : "projects-section"}>
            <h2 className="projects-title">НАШИ ПРОЕКТЫ</h2>
            <div className="projects-video__wrapper">
                {filteredHorizontalVideo.slice(-4).reverse().map((item, id) => {
                    return <VideoProject theme={props.theme} key={id} id={item.id} src={item.video} imgProject={item.img_1} client={item.client} industry={item.industry} title={item.title} orientation={item.orientation} />
                })}
            </div>
            <div className="all-projects__button-wrapper">
                <Link className={props.theme ? "all-projects__button all-projects__button--active" : "all-projects__button"} to={"/work"}>ВСЕ ПРОЕКТЫ</Link>
            </div>
        </section>
    )
}


export default ProjectsSection



