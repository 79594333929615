import React, { useEffect, useRef, useState } from "react";
import { TypeAnimation } from 'react-type-animation'
import './footer-text-anim.css'



function FooterTextAnim() {





    return (

        <TypeAnimation
            sequence={[
                '100% цифровой успех ;))',
                1000,
                'Выделяем бренды и помогаем им лидировать в своей отрасли.',
                3500,
                'С нашей поддержкой клиенты развивают свой бизнес.',
                2500,
                'Вместе направимся к взлету и успешному развитию ;)',
                2000
            ]}
            wrapper="div"
            cursor={true}
            repeat={Infinity}
        />

    )
}

export default FooterTextAnim