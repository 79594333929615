import React from "react";
import './services-section.css'

function ServicesSection(props) {
    return (
        <section className={props.theme ? "services-section services-section--active" : "services-section"}>
            <h2 className="services-title">НАШИ УСЛУГИ</h2>
            <div className="services-wrapper">
                <p className="services ar">
                    <span className={props.theme ? "services-num services-num--active" : "services-num"}>01</span>Веб-сайты
                </p>
                <p className="services al">
                    <span className={props.theme ? "services-num services-num--active" : "services-num"}>02</span>Мобильные приложения
                </p>
                <p className="services ar">
                    <span className={props.theme ? "services-num services-num--active" : "services-num"}>03</span>UI/UX
                </p>
                <p className="services al">
                    <span className={props.theme ? "services-num services-num--active" : "services-num"}>04</span>Медиапроизводство
                </p>
                <p className="services ar">
                    <span className={props.theme ? "services-num services-num--active" : "services-num"}>05</span>CRM Системы
                </p>
                <p className="services al">
                    <span className={props.theme ? "services-num services-num--active" : "services-num"}>06</span>Брендинг
                </p>
                <p className="services ar">
                    <span className={props.theme ? "services-num services-num--active" : "services-num"}>07</span>Разработка игр
                </p>
                <p className="services al">
                    <span className={props.theme ? "services-num services-num--active" : "services-num"}>08</span>Искусственный интеллект
                </p>
                <p className="services ar">
                    <span className={props.theme ? "services-num services-num--active" : "services-num"}>09</span>Кибербезопасность
                </p>
            </div>
        </section>
    )
}

export default ServicesSection