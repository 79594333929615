import React from "react";
import './work-page.css'
import { motion } from "framer-motion";
import Footer from "../../components/footer/footer";
import VideoProject from "../../components/video-project/video-project";
import project from '../../data/data.json'

function WorkPage() {

    return (
        <motion.div className="main-wrapper"
            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            transition={{ duration: 0.7, ease: "easeOut" }}
            exit={{ opacity: 0 }}
        >
            <main className="main">
                <section className="all-works-section">
                    <h1 className="all-works__title">ВСЕ ПРОЕКТЫ</h1>
                    <div className="all-works__wrapper">
                        {project == null ? <p className="project-null">Пока ничего нет</p> : project.map((item, id) => {
                            return <VideoProject key={id} id={item.id} src={item.video} imgProject={item.img_1} client={item.client} industry={item.industry} title={item.title} orientation={item.orientation} />
                        })}
                    </div>
                </section>
            </main>
            <Footer />
        </motion.div>
    )
}
export default WorkPage


