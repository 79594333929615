import React, { lazy } from "react";
import CooperateSection from "../../components/cooperate-section/cooperate-section";
import Footer from "../../components/footer/footer";
import Intro from "../../components/intro/intro";
import Loader from "../../components/loader/loader";
import WorkSection from "../../components/projects-section/projects-section";
import ServicesSection from "../../components/services-section/services-section";
import './home-page.css'
import { motion } from "framer-motion";

function HomePage(props) {

    return (
        <>
            <motion.div
                initial={{ opacity: 0, }}
                animate={{ opacity: 1, }}
                transition={{ duration: 0.7, ease: "easeOut" }}
                exit={{ opacity: 0 }}>
                <main className="main">
                    <Intro setTheme={props.setTheme} theme={props.theme} />
                    <WorkSection setTheme={props.setTheme} theme={props.theme} />
                    <ServicesSection setTheme={props.setTheme} theme={props.theme} />
                    <CooperateSection setTheme={props.setTheme} theme={props.theme} />
                </main>
                <Footer theme={props.theme} />
            </motion.div>
            <Loader showLoader={props.showLoader} setShowLoader={props.setShowLoader} />
        </>
    )
}

export default HomePage