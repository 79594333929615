import React from "react";
import { useImageSize } from 'react-image-size';



function ImageSize(props) {

    const [dimensions, { loading, error }] = useImageSize(props.img);

    return (
        (props.img || '').trim().length == 0 ? false :
            <img className={dimensions?.height > dimensions?.width ? 'h-img h-img-11' : 'v-img'} src={`/static/media/projects/${props.img}`} alt="img" />

    )
}


export default ImageSize
