import React from "react";
import Header from "../../components/header/hader";
import './layout.css'


function Layout(props) {



    return (
        <div className="layout">
            <Header />
            <>
                {props.children}
            </>
        </div>
    )
}

export default Layout