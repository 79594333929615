import React from "react";
import { Link } from "react-router-dom";
import './cooperate-section.css'
import image from "../../img/rawpixel.webp"

function CooperateSection(props) {
    return (
        <section className={props.theme ? "cooperate-section cooperate-section--active" : "cooperate-section"}>
            <h2 className="cooperate-title">РАБОТАЙ  С НАМИ</h2>
            <div className="cooperate-block__wrapper">
                {/* <img className="cooperate-block__image" src={image} alt="" /> */}
                <div className="cooperate-block">
                    <p className="cooperate-text">Мы - агентство цифровых решений с полным спектром услуг. Будь то ваш первый стартап или зрелое предприятие, мы стремимся быть вашими партнерами в повышении продаж и укреплении бренда</p>
                    <div className="cooperate-btn__wrapper">
                        <Link className={props.theme ? "cooperate-btn cooperate-btn--active" : "cooperate-btn"} to={"/about"}>БОЛЬШЕ ИНФЫ</Link>
                        <Link className={props.theme ? "cooperate-btn cooperate-btn--active" : "cooperate-btn"} to={"/work"}>ВСЕ ПРОЕКТЫ</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CooperateSection