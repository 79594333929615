import React from "react";
import { Link } from "react-router-dom";
import './next-project.css'

function NextProject(props) {

    return (
        <div className="next-project-wrapper">
            <Link className="next-project__link" to={`/project/${props.nextProject.id}`}></Link>
            <div className="next-project__title-block">
                <div>
                    <p className="next-project__meta-title">{props.nextProject.title} · {props.nextProject.industry}</p>
                    <h2 className="next-project__title">{props.nextProject.client}</h2>
                </div>
                <Link className="next-project__button" to={`/project/${props.nextProject.id}`}>Следующий проект</Link>
            </div>
            {
                props.nextProject.video.trim().length == 0 ?
                    <img className={props.nextProject.orientation == 0 ? "next-project__img--vertical" : "next-project__img--horizontal"} src={`/admin/img/${props.nextProject.img_1}`} alt="img" />
                    :
                    <iframe className={props.nextProject.orientation == 0 ? "next-project__video--vertical" : "next-project__video--horizontal"} title="vimeo-player"
                        src={`https://player.vimeo.com/video/${props.nextProject.video.substr(18)}?quality=540p&autoplay=1&controls=0&muted=1&loop=1&autopause=0&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                    </iframe >
            }

        </div>
    )
}

export default NextProject