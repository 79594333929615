import React from "react";
import './map.css'

function Map() {
    return (
        <div className="map" style={{ position: 'relative', overflow: 'hidden', }}>
            <a href="https://yandex.uz/maps/org/nimbus_technology/33354269947/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '0px' }}>
                Nimbus Technology
            </a>
            <a href="https://yandex.uz/maps/10334/samarkand/category/it_company/184106174/?utm_medium=mapframe&utm_source=maps" style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '14px' }}>
                IT-компания в Самарканде
            </a>
            <iframe src="https://yandex.uz/map-widget/v1/?ll=66.933094%2C39.682167&mode=search&oid=33354269947&ol=biz&z=17.08" width="100%" height="600" frameBorder="0" allowFullScreen={true} style={{ position: 'relative' }}></iframe>
        </div>
    )
}
export default Map