import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import './header.css'
import logo from '../../img/logo.webp'

function Header() {
    const [time, setTime] = useState()

    setInterval(() => {
        let date = new Date()
        let hours = Number(date.toISOString().substr(11, 2)) + 5
        let min = Number(date.toISOString().substr(14, 2))
        setTime(hours + ':' + min)
    }, 1000);



    const isActive = ({ isActive }) => isActive ? 'nav-pages__link header-route-link--active' : 'nav-pages__link'

    return (
        <header className="header">
            <nav className="nav">
                <div className="nav-links-wrapper">
                    <NavLink className="logo" to={"/"}>
                        <img className="logo" src={logo} width={'30px'} height={'auto'} alt="logo" />
                    </NavLink>
                    <ul className="nav-pages__list">
                        <li className="nav-pages__item">
                            <NavLink to={"/work"} className={isActive}>ВСЕ ПРОЕКТЫ</NavLink>
                        </li>
                        <li className="nav-pages__item">
                            <NavLink to={"/about"} className={isActive}>О НАС</NavLink>

                        </li>
                        <li className="nav-pages__item">
                            <NavLink to={"/contact"} className={isActive}>КОНТАКТЫ</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="nav-media__list-wrapper">
                    <ul className="nav-media__list">
                        <li className="nav-media__item">
                            <a href="https://www.instagram.com/nimbus.technology/" className="nav-media__link" target={"_blank"}>IG</a>
                        </li>
                        <li className="nav-media__item">
                            <a href="https://t.me/nimbus_uz" className="nav-media__link" target={"_blank"}>TG</a>
                        </li>
                    </ul>
                    <div className="header-time-block">
                        <p className="header-time">
                            <span>{time} </span>— SAMARKAND, UZ
                        </p>
                        <p className="header-year">
                            ©2023
                        </p>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header 