import React from "react";
import './video-project.css'
import { Link } from "react-router-dom";

function VideoProject(props) {
    console.log()

    return (
        <div className={props.orientation == 0 ? "video-project video-project--vertical" : "video-project video-project--horizontal"}>
            <Link className="project-link" to={`/project/${props.id}`}>
                <img className={(props.src || '').trim().length == 0 ?
                    props.orientation == 0 ? "video-img-static video-img--vertical" : "video-img-static video-img--horizontal"
                    :
                    props.orientation == 0 ? "video-img video-img--vertical" : "video-img video-img--horizontal"} src={`/static/media/projects/${props.imgProject}`} alt="img" />
                {(props.src || '').trim().length == 0 ? <div className="iframe"></div>
                    :
                    <iframe className="iframe" title="vimeo-player"
                        src={`https://player.vimeo.com/video/${props.src.substr(18)}?autoplay=1&controls=0&muted=1&loop=1&autopause=0&h=1d1710fbe6&title=0&byline=0&portrait=0`} frameBorder="0" allowFullScreen>
                    </iframe>
                }
            </Link>
            <div className="project-client__wrapper">
                <p className="project-client">{props.client}</p>
                <p className={props.theme ? "project-industry project-industry--active" : "project-industry"} >{props.industry}</p>
            </div>
            <p className={props.theme ? "project-title project-title--active" : "project-title"}>{props.title}</p>
        </div >
    )
}



export default VideoProject