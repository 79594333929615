import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import './about-page.css'
import { motion } from "framer-motion";
import img from '../../img/girl.webp'

function AboutPage() {

    return (
        <motion.div className="main-wrapper"
            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            transition={{ duration: 0.7, ease: "easeOut" }}
            exit={{ opacity: 0 }}
        >
            <main className="main">
                <section className="about-page-section">
                    <div className="about-page__bg-img">
                        <h1 className="about-page__title">"Nimbus Technology" ваш партнер в цифровом успехе.</h1>
                        <img className="about-page__img" src={img} alt="img" />
                        <div className="about-page__content-block">
                            <p className="about-page__content">
                                ИТ-компания "Nimbus Technology" предоставляет клиентоориентированные ИТ-услуги и решения, соответствующие вашему бизнесу. С большим опытом, мы готовы воплотить ваши идеи, постоянно совершенствуясь с использованием новейших технологий для лучшего обслуживания клиентов.
                            </p>
                            <p className="about-page__content">
                                Мы внимательно следим за тенденциями и готовы предложить полный спектр услуг для вашего бизнеса в интернете: веб-дизайн, мобильные приложения, виртуальная и дополненная реальность, разработка игр, интернет-маркетинг, ИТ-услуги.
                            </p>
                            <div className="about-page__button-wrapper">
                                <Link className="about-page__button" to={"/work"}>ВСЕ ПРОЕКТЫ</Link>
                                <Link className="about-page__button" to={"/contact"}>КОНТАКТЫ</Link>
                            </div>
                        </div>
                        <div className="advantages-block__wrapper">
                            <h2 className="advantages-block__title">НАШИ СИЛЬНЫЕ СТОРОНЫ</h2>
                            <div className="advantages-block">
                                <div className="advantages-block__text-wrappeer">
                                    <span className="advantages-block__text-num">01</span>
                                    <p className="advantages-block__text">
                                        <span className="content-des-title">
                                            Веб-сайты<br />
                                        </span>
                                        Мы разрабатываем веб-сайты с высоким качеством, от корпоративных сайтов до многофункциональных платформ.
                                    </p>
                                </div>
                                <div className="advantages-block__text-wrappeer">
                                    <span className="advantages-block__text-num">02</span>
                                    <p className="advantages-block__text">
                                        <span className="content-des-title">
                                            Мобильные приложения<br />
                                        </span>
                                        Разработка и техническая поддержка мобильных приложений любой сложности
                                    </p>
                                </div>
                                <div className="advantages-block__text-wrappeer">
                                    <span className="advantages-block__text-num">03</span>
                                    <p className="advantages-block__text">
                                        <span className="content-des-title">
                                            UI&UX<br />
                                        </span>
                                        Разработка Mind Map проекта и дизайн с оптимальным решением на основе его архитектуры
                                    </p>
                                </div>
                                <div className="advantages-block__text-wrappeer">
                                    <span className="advantages-block__text-num">04</span>
                                    <p className="advantages-block__text">
                                        <span className="content-des-title">
                                            Медиапроизводство<br />
                                        </span>
                                        Профессиональная подготовка 2D/3D анимации, презентации проекта, рекламы, видеоматериалов и других медиаресурсов.
                                    </p>
                                </div>
                                <div className="advantages-block__text-wrappeer">
                                    <span className="advantages-block__text-num">05</span>
                                    <p className="advantages-block__text">
                                        <span className="content-des-title">
                                            CRM Системы<br />
                                        </span>
                                        Автоматизация бизнеса и процессов, разработка электронных систем управления, сокращающих бумажный труд на 100%
                                    </p>
                                </div>
                                <div className="advantages-block__text-wrappeer">
                                    <span className="advantages-block__text-num">06</span>
                                    <p className="advantages-block__text">
                                        <span className="content-des-title">
                                            Брендинг<br />
                                        </span>
                                        Разработка креативного и уникального логотипа для вашего проекта и создание его брендинга
                                    </p>
                                </div>
                                <div className="advantages-block__text-wrappeer">
                                    <span className="advantages-block__text-num">07</span>
                                    <p className="advantages-block__text">
                                        <span className="content-des-title">
                                            Разработка игр<br />
                                        </span>
                                        Разработка игр любой тематики и сложности, соответствующие международному уровню.
                                    </p>
                                </div>
                                <div className="advantages-block__text-wrappeer">
                                    <span className="advantages-block__text-num">08</span>
                                    <p className="advantages-block__text">
                                        <span className="content-des-title">
                                            Искусственный интеллект<br />
                                        </span>
                                        Разработка интеллектуальных компьютерных систем, обладающих возможностями человеческого интеллекта.
                                    </p>
                                </div>
                                <div className="advantages-block__text-wrappeer">
                                    <span className="advantages-block__text-num">08</span>
                                    <p className="advantages-block__text">
                                        <span className="content-des-title">
                                            Кибербезопасность<br />
                                        </span>
                                        Поиск и устранение уязвимостей в сетях, устройствах. кодах и в данных.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </motion.div>
    )
}

export default AboutPage