import React from "react";
import './intro.css'
import img from '../../img/1.webp'
import { Link } from "react-router-dom";

function Intro(props) {
    return (
        <section className={props.theme ? "intro-section intro-section--active" : "intro-section"} >
            <h1 className="title">
                "Nimbus Technology: Вместе создаем будущее вашего бизнеса!"
            </h1>
            <div className="description-block">
                <div className="description-wrapper">
                    <p className="description">
                        "IТ-компания "Nimbus Technology" предоставляет ИТ-услуги и решения, оптимально подходящие для вашего бизнеса. С обширным опытом, мы воплотим ваши идеи в реальность."
                    </p>
                    <p className="description">
                        Наша компания внимательно следит за тенденциями и динамикой; поэтому мы знаем, что действительно может удовлетворить потребности вашего бизнеса, так же гладко, как хорошо сидящая перчатка).
                    </p>
                    <div className="description__button-wrapper">
                        <Link className={props.theme ? "description__button description__button--active" : "description__button"} to={"/about"}>БОЛЬШЕ ИНФЫ</Link>
                        <Link className={props.theme ? "description__button description__button--active" : "description__button"} to={"/work"}>ВСЕ ПРОЕКТЫ</Link>
                    </div>
                </div>
                <img className="description-block__img" src={img} alt="img" />
            </div>
        </section>
    )
}

export default Intro