import React, { useState, useEffect } from 'react';
import './App.css';
import Layout from './hoc/layout/layout';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/home-page/home-page';
import WorkPage from './pages/work-page/work-page';
import AboutPage from './pages/about-page/about-page';
import ContactPage from './pages/contact-page/contact-page';
import { AnimatePresence } from 'framer-motion';
import ProjectCard from './pages/project-card/project-card';


function App() {
  const [showLoader, setShowLoader] = useState(true)
  const [theme, setTheme] = useState(false)

  const changeBg = () => {
    if (window.screen.width > 1400) {
      if (window.scrollY < 300) {
        setTheme(false)
      }
      if (window.scrollY > 2400) {
        setTheme(true)
      }
      if (window.scrollY > 3400) {
        setTheme(false)
      }
    }
    if (window.screen.width <= 1200) {
      if (window.scrollY < 300) {
        setTheme(false)
      }
      if (window.scrollY > 1500) {
        setTheme(true)
      }
      if (window.scrollY > 2200) {
        setTheme(false)
      }
    }

    if (window.screen.width <= 960) {
      if (window.scrollY < 300) {
        setTheme(false)
      }
      if (window.scrollY > 1300) {
        setTheme(true)
      }
      if (window.scrollY > 2000) {
        setTheme(false)
      }
    }
  }


  window.addEventListener('scroll', changeBg)

  const location = useLocation();


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 700);
  }, [location]);

  return (
    <Layout setTheme={setTheme} theme={theme}>
      <AnimatePresence mode={"wait"}>
        <Routes location={location} key={location.pathname}>
          <Route path='/' element={<HomePage setTheme={setTheme} theme={theme} setShowLoader={setShowLoader} showLoader={showLoader} />} />
          <Route path='/work' element={<WorkPage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/project/:id' element={<ProjectCard />} />
          <Route path='*' element={<HomePage setTheme={setTheme} theme={theme} setShowLoader={setShowLoader} showLoader={showLoader} />} />
        </Routes>
      </AnimatePresence>
    </Layout>
  );
}

export default App;
