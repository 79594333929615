import React from "react";
import Footer from "../../components/footer/footer";
import './contact-page.css'
import { motion } from "framer-motion";
import Map from "../../components/map/map";


function ContactPage() {

    return (
        <motion.div className="main-wrapper"
            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            transition={{ duration: 0.7, ease: "easeOut" }}
            exit={{ opacity: 0 }}
        >
            <main className="main">
                <section className="contact-section">
                    <h1 className="contact-title">КОНТАКТЫ</h1>
                    {/* <video className="contact-video" autoPlay muted loop playsInline>
                        <source src={teamMov} type="video/quicktime" />
                        <source src={teamWebm} type="video/webm" />
                    </video> */}
                    <div className="contact-form__wrapper">
                        <div className="contact-form__text-wrapper">
                            <div className="contact-form__text-block">
                                <span className="contact-form__text-title">Работайте с нами</span>
                                <p className="contact-form__text">
                                    Мы всегда готовы работать с вами и поделиться новыми идеями. Если вы заинтересованы в сотрудничестве с нами, отправьте нам электронное письмо с небольшой информацией о вашей компании и  о вашем проекте.
                                </p>
                            </div>
                            <div className="contact-form__text-block">
                                <span className="contact-form__text-title">Новые возможности для талантов</span>
                                <p className="contact-form__text">
                                    Мы всегда рады сотрудничать с новыми людьми которые хотят работать с нами. Если вы или кто-то из ваших знакомых может быть заинтересован в сотрудничестве, пожалуйста, свяжитесь с нами.
                                </p>
                            </div>
                        </div>
                        <form className="contact-form">
                            <input className="contact-input" type="text" placeholder="Имя" />
                            <input className="contact-input" type="text" placeholder="Электронная почта" />
                            <textarea className="contact-textarea" placeholder="Комментарий"></textarea>
                            <button type="submit" className="contact-btn" >Отправить</button>
                        </form>
                    </div>
                    <Map />
                </section>
            </main>
            <Footer />
        </motion.div>
    )
}

export default ContactPage




