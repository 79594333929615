import React from "react";
import { NavLink } from "react-router-dom";
import FooterTextAnim from "../footer-text-anim/footer-text-anim";
import './footer.css'

function Footer(props) {

    function isActive({ isActive }) {
        if (isActive === true) {
            return `footer-route-link--active ${props.theme ? "footer-link footer-link--active" : "footer-link"}`
        }
        if (props.theme === true) {
            return "footer-link footer-link--active"
        }
        else {
            return "footer-link"
        }
    }

    return (
        <footer className={props.theme ? "footer footer--active" : "footer"}>
            <div className="footer-block__wrapper">
                <div className="footer-block footer-links">
                    <NavLink className={isActive} to={"/"}>ГЛАВНАЯ</NavLink>
                    <NavLink className={isActive} to={"/about"}>О НАС</NavLink>
                    <NavLink className={isActive} to={"/work"}>ПРОЕКТЫ</NavLink>
                    <NavLink className={isActive} to={"/contact"}>КОНТАКТЫ</NavLink>
                </div>
                <div className="footer-block footer-media-links">
                    <p className="footer-madia__title">СОЦ. СЕТИ</p>
                    <a href="https://www.instagram.com/nimbus.technology/" className={props.theme ? "footer-media__link--active mbt footer-media__link" : "footer-media__link mbt"} target={"_blank"}>INSTAGRAM</a>
                    <a href="https://t.me/nimbus_uz" className={props.theme ? "footer-media__link--active mbt footer-media__link" : "footer-media__link mbt"} target={"_blank"}>TELEGRAM</a>
                    <p className="footer-madia__title">КОНТАКТЫ</p>
                    <a href={`mailto:#`} className={props.theme ? "footer-media__link--active mbt footer-media__link" : "footer-media__link mbt"} target={"_blank"}>@nimbus</a>
                    <a href={`tel:+998987778505`} className={props.theme ? "footer-media__link--active footer-media__link" : "footer-media__link"} target={"_blank"}>+998(98)777-85-05</a>
                </div>
                <div className="footer-block footer-copyright-block">
                    <div className="footer-text" >
                        <FooterTextAnim />
                    </div>
                    <p className="footer-copyright">©2023 Nimbus Technology.</p>
                </div>
            </div>
        </footer>
    )
}


export default Footer


